import { getData, getItems } from '@portal-internet/core';
import Carrussel from 'components/3cat/Carrussel';
import { useQuery } from 'react-query';

export default function CarrusselWrapper(props) {
    const { prefetchedData, id, urlDadesByProducte, field } = props;
    let { items } = props;

    const initialData = prefetchedData?.[id] ? prefetchedData?.[id] : null;
    const { data } = useQuery([field, { url: urlDadesByProducte }], getData, {
        initialData: initialData,
        staleTime: Infinity,
        refetchOnMount: !initialData ? 'always' : false
    });
    items = items || getItems(data);
    return items ? <Carrussel items={items} /> : null;
}
